import React from "react";
import styled, { keyframes } from "styled-components";
import { Typography } from "@mui/material";

const moveText = keyframes`
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CarouselText = styled.div`
  display: inline-block;
  animation: 8s ${moveText} infinite linear;
`;

const MovingText = () => {
  return (
    <CarouselContainer>
      <CarouselText>
        <Typography variant="subtitle2" textAlign="center" display="flex">
          <span style={{ marginRight: "1rem" }}>☎️☎️✨</span>
          Call to Order Take-Out! <span style={{ marginLeft: "1rem" }}>🍝🍝🍝</span>
          <span style={{ marginLeft: "1rem" }}>Now Open For Lunch! On Saturdays</span>
          <span style={{ marginLeft: "1rem" }}>🍋🍋🍋</span>
        </Typography>
      </CarouselText>
    </CarouselContainer>
  );
};

export default MovingText;

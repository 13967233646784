import { Box, Container } from "@mui/material";
// @ts-ignore
import CurrentMenu from "../images/current-menu.jpg";
// @ts-ignore
import MobileMenuPage1 from "../images/mobile-menu-page-1.jpeg";
// @ts-ignore
import MobileMenuPage2 from "../images/mobile-menu-page-2.jpeg";
// @ts-ignore
import MobileMenuPage3 from "../images/mobile-menu-page-3.jpeg";

import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

const Menu = () => {
  const { isDevice } = useScreenSizes();

  return (
    <Box bgcolor={isDevice ? "#f3e4eb" : "#E9E9D1"} padding={isDevice ? "1rem" : "2rem"}>
      {!isDevice && (
        <Container
          sx={{
            backgroundImage: `url(${CurrentMenu})`,
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            alignItems: "center",
            paddingTop: "5rem",
            height: "120vh",
            margin: "0 auto",
            borderWidth: "2px",
            borderColor: "black",
            borderStyle: "solid",
            borderRadius: "15px",
          }}
          className="scrolling-container"
        ></Container>
      )}
      {isDevice && (
        <Box width="100%">
          <img src={MobileMenuPage2} alt="" style={{ width: "100%" }} />
          <img src={MobileMenuPage3} alt="" style={{ width: "100%" }} />
          <img src={MobileMenuPage1} alt="" style={{ width: "100%" }} />
        </Box>
      )}
    </Box>
  );
};

export default Menu;
